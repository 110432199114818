import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Layout from "src/components/layout"
import SubpageBanner from "src/components/SubpageBanner"
import SEO from "src/components/seo"
import SimpleText from "src/components/simple-text"
import Breadcrumbs from "src/components/Breadcrumbs"
import Sidebar from "src/components/Sidebar"

const breadcrumbs = [
  { url: "a-better-way", handle: "A Better Way" },
  {
    url: "a-better-way/using-excellerator",
    handle: "Using the Excellerator",
  },
]

const History = () => (
  <StaticQuery
    query={graphql`
      query {
        one: file(relativePath: { eq: "excellerator-4.jpg" }) {
          childImageSharp {
            fixed(quality: 100) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        background: file(relativePath: { eq: "discarded-cells-banner.jpg" }) {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => {
      const one = data.one.childImageSharp.fixed
      const background = data.background.childImageSharp.fluid

      return (
        <Layout>
          <SEO title="Using the Excellerator - ExCellerator" />
          <SubpageBanner image={background} heading="Using the Excellerator" />
          <div className="wrapper wrapper--narrow p-bottom p-sides">
            <Breadcrumbs items={breadcrumbs} />
            <div className="split-contain wrapper">
              <div className="split-contain__sidebar">
                <Sidebar image={one} />
              </div>
              <div className="split-contain__contain">
                <SimpleText
                  heading="SAMPLING INSTRUCTIONS"
                  >
                    <p><strong>TRANSFERRING ECTOCERVICAL SAMPLE</strong></p>
                    <p>
                      1. Adequately sample the ectocervix by applying the dog bone end
                      of the spatula to the cervix and, while maintaining firm contact, rotate it
                      360&deg; to collect material from the entire ectocervical surface.  Remove
                      the spatula and place directly into the vial of PreservCyt&reg; solution.
                    </p>
                    <p>
                      2. Transfer the sample from the spatula into the vial by swirling the dog-bone
                      end of the spatula vigorously ten (10) in the PreservCyt&reg; solution.
                    </p>
                    <p>
                      Set spatula temporarily aside.  Do not throw away.
                    </p>
                    <p><strong>TRANSFERRING ECTOCERVICAL SAMPLE</strong></p>
                    <p>
                      Sample the endocervix using an IVD labeled endocervical brush,
                      transfer the brush sample into the vial per instructions specified in the
                      cytobrush or ThinPrep Pap test label.  THEN:
                    </p>
                    <p>
                      1. Run the brush down along the "rails" of the exCellerator&trade; spatula,
                      and while keeping them both submersed, move the brush bristles in-and-out of the hole in the ExCellerator&trade; spatula
                      10 times while rotating the shaft between the fingertips.  Swirl the brush vigorously to release additional material.
                    </p>
                    <p>2. Remove the brush and discard it.  Swirl the ExCellerator&trade; spatula in the solution
                      up to five more times, then discard it.  Replace and tighten the vial cap so that the torque line on the cap passes the torque
                      line on the vial.
                    </p>
                  </SimpleText>
              </div>
            </div>
          </div>
        </Layout>
      )
    }}
  />
)

export default History
